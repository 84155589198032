import '@babel/polyfill';
import React from 'react'
import dynamic from 'next/dynamic'
const LadningPage = dynamic(() => import('../components/BrushingMaster/LandingPage'))


class Kids extends React.Component{
    render(){
        return(<div>
            <LadningPage />
        </div>)
    }
}

export default Kids